body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
main{
  padding-top:50px;
  padding-bottom: 50px;
}
.paginationWrap{
  margin:20px auto 0px;
}
